<div class="m-3">
    <h1 mat-dialog-title>Créer une prestation</h1>
    <mat-dialog-content>
        <form [formGroup]="form">
            <div class="d-flex gap-2 align-items-center">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option *ngFor="let type of PrestationTypeEnum.VALUES" [value]="type">
                            {{ type.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox formControlName="urgent">Urgent</mat-checkbox>
                <mat-checkbox formControlName="doubledPrestation">Prestation doublée</mat-checkbox>
                <mat-checkbox formControlName="manualAddress">Hors domicile</mat-checkbox>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Bénéficiaire</mat-label>
                    <mat-select formControlName="beneficiary" required>
                        <input class="select-filter" [(ngModel)]="beneficiaryFilter"
                               placeholder="Rechercher"
                               [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let beneficiary of filteredBeneficiaries()" [value]="beneficiary">
                            {{ beneficiary | personInfo }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Demande</mat-label>
                    <mat-select formControlName="requestContact" required>
                        <mat-option *ngFor="let contact of selectedBeneficiary()?.contacts" [value]="contact">
                            {{ contact | personInfo }} ({{ contact.linkType }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Facturation</mat-label>
                    <mat-select formControlName="invoiceContact" required>
                        <mat-option *ngFor="let contact of selectedBeneficiary()?.contacts" [value]="contact">
                            {{ contact | personInfo }} ({{ contact.linkType }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox formControlName="bap">BAP</mat-checkbox>
            </div>
            <div>
                <app-create-address-fields formGroupName="address"></app-create-address-fields>
            </div>
            <div class="d-flex gap-2 align-items-center">
                <mat-form-field>
                    <mat-label>Date de début</mat-label>
                    <input matInput required [matDatepicker]="startDatePicker" formControlName="startDate">
                    <mat-hint>JJ/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Début</mat-label>
                    <input matInput required type="text" formControlName="startTime">
                    <mat-hint>HH:mm (10:00)</mat-hint>
                    <button *ngIf="form.value.startTime" matSuffix mat-icon-button aria-label="Clear"
                            (click)="form.patchValue({startTime:''})" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Fin</mat-label>
                    <input matInput required type="text" formControlName="endTime">
                    <mat-hint>HH:mm (13:00)</mat-hint>
                    <button *ngIf="form.value.endTime" matSuffix mat-icon-button aria-label="Clear"
                            (click)="form.patchValue({endTime:''})" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-icon *ngIf="isCoveringTwoDays()" class="warning-icon" id="warningPrestationCoversTwoDays"
                          matTooltip="La prestation couvre 2 jours">
                    warning
                </mat-icon>
            </div>
            <div>
                <app-select-with-filter
                        label="Educateur principal"
                        [controlName]="'mainEducator'"
                        [items]="filteredMainEducators()"
                        [compareFunction]="byId"
                        [sortFunction]="sortByFullName"
                        [optionDisplay]="fullName">
                </app-select-with-filter>
                <app-select-with-filter *ngIf="isDoubledPrestation()"
                                        label="Educateur accompagnant"
                                        [controlName]="'secondaryEducator'"
                                        [items]="filteredSecondaryEducators()"
                                        [compareFunction]="byId"
                                        [sortFunction]="sortByFullName"
                                        [optionDisplay]="fullName">
                </app-select-with-filter>
            </div>
            <div>
                <mat-form-field class="input-note">
                    <mat-label>Note</mat-label>
                    <textarea matInput cdkTextareaAutosize formControlName="note"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="create" [disabled]="!isFormValid()" (click)="createAndContinue()">Créer
        </button>
        <button class="btn btn-primary" id="createAndAccept" [disabled]="!canCreateAndValidate()"
                (click)="createAndAccept()">Créer et accepter
        </button>
        <button class="btn btn-primary" id="createAndView" [disabled]="!isFormValid()" (click)="createAndView()">Créer
            et
            visualiser
        </button>
    </mat-dialog-actions>
</div>