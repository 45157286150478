import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Dashboard} from "./core/views/dashboard/dashboard";
import {AuthGuard} from "./AuthGuard";
import {SchedulerComponent} from "./core/views/scheduler/scheduler.component";
import {ListBeneficiaryComponent} from "./core/views/beneficiary/list-beneficiary/list-beneficiary.component";
import {DetailBeneficiaryComponent} from "./core/views/beneficiary/detail-beneficiary/detail-beneficiary.component";
import {CreateBeneficiaryComponent} from "./core/views/beneficiary/create-beneficiary/create-beneficiary.component";
import {ListFirstContactComponent} from "./core/views/first-contact/list-first-contact/list-first-contact.component";
import {
  DetailFirstContactComponent
} from "./core/views/first-contact/detail-first-contact/detail-first-contact.component";
import {
  CreateFirstContactComponent
} from "./core/views/first-contact/create-first-contact/create-first-contact.component";
import {EditFirstContactComponent} from "./core/views/first-contact/edit-first-contact/edit-first-contact.component";
import {ListStaffComponent} from "./core/views/staff/list-staff/list-staff.component";
import {DetailStaffComponent} from "./core/views/staff/detail-staff/detail-staff.component";
import {GenerateReportKmComponent} from "./core/views/document/generate-report-km/generate-report-km.component";
import {
  GenerateRepitInvoiceComponent
} from "./core/views/document/generate-repit-invoice/generate-repit-invoice.component";
import {GenerateBapInvoiceComponent} from "./core/views/document/generate-bap-invoice/generate-bap-invoice.component";
import {
  ManagementDashboardComponent
} from "./core/views/management/management-dashboard/management-dashboard.component";
import {UserDetailComponent} from "./core/views/user/user-detail/user-detail.component";
import {MyWorkScheduleComponent} from "./core/views/my-work/my-work-schedule/my-work-schedule.component";
import {ADMIN_AND_AS, ADMIN_ONLY} from './core/stores/user/user.state';
import {ListPrestationComponent} from "./core/views/prestation/list-prestation/list-prestation.component";
import {DetailPrestationComponent} from "./core/views/prestation/detail-prestation/detail-prestation.component";
import {
  ListMyActivityRequestComponent
} from "./core/views/my-work/list-staff-activity_request/list-my-activity-request.component";
import {
  DetailStaffActivityRequestComponent
} from "./core/views/my-work/detail-staff-activity-request/detail-staff-activity-request.component";
import {
  ListActivityRequestsComponent
} from "./core/views/activity/list-activity-requests/list-activity-requests.component";


const routes: Routes = [

  {path: 'dashboard', component: Dashboard, canActivate: [AuthGuard]},
  {path: 'activity', redirectTo: 'activity/schedule'},
  {path: 'activity/schedule', component: SchedulerComponent, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {
    path: 'activity/request',
    component: ListActivityRequestsComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'activity/request/detail/:id',
    component: DetailStaffActivityRequestComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {path: 'my-work', redirectTo: 'my-work/schedule'},
  {path: 'my-work/schedule', component: MyWorkScheduleComponent, canActivate: [AuthGuard]},
  {path: 'my-work/request/list', component: ListMyActivityRequestComponent, canActivate: [AuthGuard]},
  {path: 'my-work/request/detail/:id', component: DetailStaffActivityRequestComponent, canActivate: [AuthGuard]},
  {path: 'beneficiary', redirectTo: 'beneficiary/list'},
  {
    path: 'beneficiary/list',
    component: ListBeneficiaryComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'beneficiary/detail/:id',
    component: DetailBeneficiaryComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'beneficiary/create',
    component: CreateBeneficiaryComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {path: 'first-contact', redirectTo: 'first-contact/list'},
  {
    path: 'first-contact/list',
    component: ListFirstContactComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'first-contact/detail/:id',
    component: DetailFirstContactComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'first-contact/create',
    component: CreateFirstContactComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'first-contact/edit/:id',
    component: EditFirstContactComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {path: 'prestation', redirectTo: 'prestation/list'},
  {
    path: 'prestation/list',
    component: ListPrestationComponent,
    canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'prestation/detail/:id',
    component: DetailPrestationComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {path: 'prestation/create', component: Dashboard, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {path: 'staff', redirectTo: 'staff/list'},
  {path: 'staff/list', component: ListStaffComponent, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {path: 'staff/detail/:id', component: DetailStaffComponent, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {path: 'staff/volunteer', component: Dashboard, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {path: 'document', redirectTo: 'document/generate-report-km'},
  {
    path: 'document/generate-report-km',
    component: GenerateReportKmComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {path: 'document/ra', component: Dashboard, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {
    path: 'document/generate-repit-invoice',
    component: GenerateRepitInvoiceComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {
    path: 'document/generate-bap-invoice',
    component: GenerateBapInvoiceComponent,
    canActivate: [AuthGuard],
    data: {roles: ADMIN_AND_AS}
  },
  {path: 'document/out', component: Dashboard, canActivate: [AuthGuard], data: {roles: ADMIN_AND_AS}},
  {path: 'management', component: ManagementDashboardComponent, canActivate: [AuthGuard], data: {roles: ADMIN_ONLY}},
  {path: 'resource', component: Dashboard, canActivate: [AuthGuard]},
  {path: 'user', component: UserDetailComponent, canActivate: [AuthGuard]},
  {path: '', component: Dashboard, canActivate: [AuthGuard]},
  {path: '**', redirectTo: 'dashboard'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], exports: [RouterModule]
})
export class AppRoutingModule {
}
