import {Component, input} from '@angular/core';
import {DatePipe, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";

@Component({
  selector: 'app-activity-request-card',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    RouterLink
  ],
  templateUrl: './activity-request-card.component.html',
  styleUrl: './activity-request-card.component.scss'
})
export class ActivityRequestCardComponent {

  request = input.required<StaffActivityRequest>();
  goToUrl = input.required<string>();
  showStaffName = input<boolean>(false);

  shouldShowTime() {
    return !this.request().type.dayOnly;
  }
}
