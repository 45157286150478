import {Component, computed, inject, Signal, signal} from '@angular/core';
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {StaffActivityTypeEnum} from "../../../models/staff/activity/StaffActivityType";
import {StaffActivityRequestStatusEnum} from "../../../models/staff/activity/StaffActivityRequestStatus";
import {FormsModule} from "@angular/forms";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {StaffActivityRequestFilter, StaffState} from "../../../stores/staff/staff.state";
import {MatDialog} from "@angular/material/dialog";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {CreateSickLeave, FindStaffActivityRequests} from "../../../stores/staff/staff.actions";
import {Store} from "@ngxs/store";
import {RepitPagerComponent} from "../../components/repit-pager/repit-pager.component";
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {ActivityRequestCardComponent} from "../activity-request-card/activity-request-card.component";
import {CalendarService} from "../../../models/calendar/calendar.service";
import {Year} from "../../../models/calendar/Year";
import StaffActivityType = StaffActivityTypeEnum.StaffActivityType;
import StaffActivityRequestStatus = StaffActivityRequestStatusEnum.StaffActivityRequestStatus;

@Component({
  selector: 'app-list-activity-requests',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    FormsModule,
    MatIconButton,
    MatInput,
    MatSuffix,
    NgIf,
    RepitPagerComponent,
    ActivityRequestCardComponent
  ],
  templateUrl: './list-activity-requests.component.html',
  styleUrl: './list-activity-requests.component.scss'
})
export class ListActivityRequestsComponent {

  store = inject(Store);

  yearFilter = signal<Year>(new Year(new Date().getFullYear()));
  educatorFilter = signal('');
  typeFilter = signal<StaffActivityType[]>([]);
  statusFilter = signal<StaffActivityRequestStatus[]>([]);

  filter = computed(() => ({
    year: this.yearFilter(),
    educator: this.educatorFilter(),
    type: this.typeFilter(),
    status: this.statusFilter()
  } as StaffActivityRequestFilter))

  requests: Signal<StaffActivityRequest[]> = toSignal(this.store.select(StaffState.activityRequests))

  constructor(private calendarService: CalendarService, public dialog: MatDialog) {
    const filterFromState = this.store.selectSnapshot(StaffState.activityRequestFilter);
    this.educatorFilter.set(filterFromState.educator);
    this.typeFilter.set(filterFromState.type);
    this.statusFilter.set(filterFromState.status);
    this.yearFilter.set(filterFromState.year);
    toObservable(this.filter).subscribe(filter => {
      this.store.dispatch(new FindStaffActivityRequests(filter));
    })
  }

  protected readonly StaffActivityTypeEnum = StaffActivityTypeEnum;
  protected readonly StaffActivityRequestStatusEnum = StaffActivityRequestStatusEnum;

  create() {
    this.store.dispatch(new CreateSickLeave())
  }

  years = computed(() => this.calendarService.getYears());
}
