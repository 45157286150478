import {Component, computed, input, OnInit, signal} from '@angular/core';
import {FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatOption} from "@angular/material/autocomplete";
import {MatFormField, MatLabel, MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-select-with-filter',
  standalone: true,
  imports: [
    FormsModule,
    MatOption,
    MatSelect,
    NgForOf,
    ReactiveFormsModule,
    MatFormField,
    MatLabel
  ],
  templateUrl: './select-with-filter.component.html',
  styleUrl: './select-with-filter.component.scss'
})
export class SelectWithFilterComponent<T> implements OnInit {

  filter = signal('');
  label = input.required<string>();
  items = input.required<T[]>();
  controlName = input.required<string>();
  compareFunction = input<(a: any, b: any) => boolean>((a: T, b: T) => a === b);
  sortFunction = input<(a: any, b: any) => number>((a: T, b: T) => a.toString() > b.toString() ? 1 : -1);
  optionDisplay = input<(option: any) => string>((option: T) => option.toString());
  formControl: FormControl;

  filteredItems = computed(() => {
    return this.items()
      .filter(option =>
        this.optionDisplay()(option).toLowerCase().includes(this.filter().toLowerCase())
      )
      .sort(this.sortFunction());
  });

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.formControl = this.rootFormGroup.control.get(this.controlName()) as FormControl;
  }
}
