<h1>Demande de congés et absences</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field>
            <mat-label>Année</mat-label>
            <mat-select [(ngModel)]="yearFilter">
                <mat-option *ngFor="let year of years()" [value]="year">{{ year.value }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Educateur</mat-label>
            <input matInput type="text" [(ngModel)]="educatorFilter">
            <button *ngIf="educatorFilter()" matSuffix mat-icon-button aria-label="Clear"
                    (click)="educatorFilter.set('')" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="typeFilter" name="requestType" multiple>
                <mat-option *ngFor="let type of StaffActivityTypeEnum.VALUES" [value]="type">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Statut</mat-label>
            <mat-select [(ngModel)]="statusFilter" name="requestStatus" multiple>
                <mat-option *ngFor="let status of StaffActivityRequestStatusEnum.VALUES" [value]="status">
                    {{ status.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <a (click)="create()" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Créer une demande
            </div>
        </a>
    </div>

    <app-repit-pager [items]="requests()"
                     [itemTemplate]="cardTemplate">
        <ng-template #cardTemplate let-request>
            <app-activity-request-card class="card repit-card"
                                       [request]="request"
                                       [showStaffName]="true"
                                       [goToUrl]="'/activity/request/detail'"
            ></app-activity-request-card>
        </ng-template>
    </app-repit-pager>


</div>