import {createPropertySelectors, createSelector} from "@ngxs/store";
import {StaffState, StaffStateModel} from "./staff.state";
import {StaffTypeEnum} from "../../models/staff/StaffType.enum";
import {SimpleEducator} from "../../models/educator/simpleEducator";

export class StaffSelectors {
  static slices = createPropertySelectors<StaffStateModel>(StaffState);

  static summaries() {
    return createSelector(
      [StaffSelectors.slices.staffMembers],
      staffMembers => staffMembers);
  }

  static detail() {
    return createSelector(
      [StaffSelectors.slices.selectedStaffMember],
      staffMember => staffMember);
  }

  static activeEducators() {
    return createSelector(
      [StaffSelectors.slices.activeEducators],
      staffMembers => staffMembers
        .filter(staffMember => staffMember.active && staffMember.type === StaffTypeEnum.EDUCATOR)
        .map(staffMember => {
          return {
            id: staffMember.id,
            firstName: staffMember.firstName,
            lastName: staffMember.lastName
          } as SimpleEducator
        })
    );
  }

  static activeStaffMembers() {
    return createSelector(
      [StaffSelectors.slices.staffMembers],
      staffMembers => staffMembers.filter(staffMember => staffMember.active)
    );
  }
}