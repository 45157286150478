import {Injectable} from '@angular/core';
import {Month} from "./Month";
import {Year} from "./Year";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private readonly MONTHS: Map<number, Month> = new Map<number, Month>();
  private readonly AVAILABLE_YEARS: Map<number, Year> = new Map<number, Year>();

  constructor() {
    this.MONTHS.set(1, {name: "Janvier", value: 1});
    this.MONTHS.set(2, {name: "Février", value: 2});
    this.MONTHS.set(3, {name: "Mars", value: 3});
    this.MONTHS.set(4, {name: "Avril", value: 4});
    this.MONTHS.set(5, {name: "Mai", value: 5});
    this.MONTHS.set(6, {name: "Juin", value: 6});
    this.MONTHS.set(7, {name: "Juillet", value: 7});
    this.MONTHS.set(8, {name: "Août", value: 8});
    this.MONTHS.set(9, {name: "Septembre", value: 9});
    this.MONTHS.set(10, {name: "Octobre", value: 10});
    this.MONTHS.set(11, {name: "Novembre", value: 11});
    this.MONTHS.set(12, {name: "Décembre", value: 12});

    this.AVAILABLE_YEARS.set(2025, {value: 2025});
    this.AVAILABLE_YEARS.set(2024, {value: 2024});
    this.AVAILABLE_YEARS.set(2023, {value: 2023});
    this.AVAILABLE_YEARS.set(2022, {value: 2022});
    this.AVAILABLE_YEARS.set(2021, {value: 2021});
  }

  public getMonths(): Month[] {
    return Array.from(this.MONTHS.values());
  }

  public getYears(): Year[] {
    return Array.from(this.AVAILABLE_YEARS.values());
  }

  public getCurrentMonth(): Month {
    return this.MONTHS.get(new Date().getMonth() + 1);
  }

  public getCurrentYear(): Year {
    return this.AVAILABLE_YEARS.get(new Date().getFullYear());
  }
}
