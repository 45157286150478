import {StaffMemberSummary} from "../../models/staff/StaffMemberSummary.model";
import {StaffMemberDetail} from "../../models/staff/StaffMemberDetail";
import {AddressWithHistory} from "../../models/address/AddressWithHistory";
import {TimeOutCompRequest} from "../../models/staff/activity/TimeOutCompRequest";
import {TimeOffRequest} from "../../models/staff/activity/TimeOffRequest";
import {StaffActivityRequestFilter} from "./staff.state";
import {StaffActivityRequest} from "../../models/staff/activity/StaffActivityRequest";
import {SickLeaveRequest} from "../../models/staff/activity/SickLeaveRequest";

export class RetrieveStaffMembers {
  public static readonly type = '[Staff list] List staff members';
}

export class StaffMembersRetrieved {
  public static readonly type = '[Staff API] Staff members retrieved';

  constructor(public staffMembers: StaffMemberSummary[]) {
  }
}

export class RetrieveActiveEducators {
  public static readonly type = '[Staff list] List active educators';
}

export class ActiveEducatorsRetrieved {
  public static readonly type = '[Staff API] Active educators retrieved';

  constructor(public educators: StaffMemberSummary[]) {
  }
}

export class RetrieveStaffMember {
  public static readonly type = '[Staff list] Retrieve staff member';

  constructor(public staffMemberId: number) {
  }
}

export class StaffMemberRetrieved {
  public static readonly type = '[Staff API] Staff member retrieved';

  constructor(public staffMember: StaffMemberDetail) {
  }
}

export class UpdateStaffMemberAddress {
  public static readonly type = '[Staff detail] Update staff member address';

  constructor(public staffMemberId: number, public addressToUpdate: AddressWithHistory) {
  }
}

export class CreateTimeOutCompRequest {
  public static readonly type = '[Staff detail] Create time out compensation request';

  constructor(public request: TimeOutCompRequest, public close: boolean = true) {
  }
}

export class CreateTimeOffRequest {
  public static readonly type = '[Staff detail] Create time off request';

  constructor(public request: TimeOffRequest, public close: boolean = true) {
  }
}

export class CreateSickLeave {
  public static readonly type = '[Staff detail] Init create sick leave';
}


export class ConfirmSickLeaveCreation {
  public static readonly type = '[Staff API] Confirm sick leave creation';

  constructor(public request: SickLeaveRequest, public close: boolean = true) {
  }
}

export class FindMyStaffActivityRequests {
  public static readonly type = '[Staff list] Find my staff activity requests';

  constructor(public filter: StaffActivityRequestFilter) {
  }
}

export class FindStaffActivityRequests {
  public static readonly type = '[Staff list] Find staff activity requests';

  constructor(public filter: StaffActivityRequestFilter) {
  }
}

export class FindActivityRequest {
  public static readonly type = '[Staff list] Find activity request';

  constructor(public activityRequestId: number) {
  }
}

export class CancelStaffActivityRequest {
  public static readonly type = '[Staff detail] Cancel staff activity request';

  constructor(public request: StaffActivityRequest) {
  }
}

export class ConfirmStaffActivityRequestCancellation {
  public static readonly type = '[Staff API] Confirm staff activity request cancellation';

  constructor(public request: StaffActivityRequest) {
  }
}

export class RefuseStaffActivityRequest {
  public static readonly type = '[Staff detail] Refuse staff activity request';

  constructor(public request: StaffActivityRequest) {
  }
}

export class ConfirmStaffActivityRequestRefusal {
  public static readonly type = '[Staff API] Confirm staff activity request refusal';

  constructor(public request: StaffActivityRequest, public note: string) {
  }
}

export class AcceptStaffActivityRequest {
  public static readonly type = '[Staff detail] Accept staff activity request';

  constructor(public request: StaffActivityRequest) {
  }
}

export class ConfirmTimeOffRequestAcceptance {
  public static readonly type = '[Staff API] Confirm time off request acceptance';

  constructor(public request: StaffActivityRequest) {
  }
}

export class ConfirmTimeOutRequestAcceptance {
  public static readonly type = '[Staff API] Confirm time out request acceptance';

  constructor(public request: StaffActivityRequest) {
  }
}