<div class="vstack gap-2" *ngIf="request()">
    <div class="repit-container-2">
        <div class="box general-bg bg-white">
            <div class="d-flex align-items-baseline">
                <h2>Informations sur la demande</h2>
            </div>
            <div class="repit-field">
                <div>Type :</div>
                <div>{{ request().type.label }}</div>
            </div>

            <div class="repit-field">
                <div>Statut :</div>
                <div>{{ request().status.label }}</div>
            </div>

            <div class="repit-field" *ngIf="isRefused()">
                <div>Justification :</div>
                <div>{{ request().refusalNote }}</div>
            </div>

            <div class="repit-field" id="date">
                <div>Date :</div>
                <div *ngIf="!shouldShowTime()">{{ request().startDate | date:"dd/MM/YYYY" }}
                    - {{ request().endDate | date:"dd/MM/YYYY" }}
                </div>
                <div *ngIf="shouldShowTime()">{{ request().startDate | date:"dd/MM/YYYY" }} {{ request().startDate | date:"HH:mm" }}
                    - {{ request().endDate | date:"HH:mm" }}
                </div>
            </div>

            <div class="repit-field">
                <div>Demandée par :</div>
                <div>{{ request().requesterFullName }}</div>
            </div>

            <div class="repit-field">
                <div>Créée le :</div>
                <div>{{ request().requestedAt | date:"dd/MM/YYYY HH:mm" }}</div>
            </div>
        </div>
    </div>

    <div class="general-bg bg-white d-flex gap-2">
        <button backButton class="btn btn-secondary">Retour</button>
        <button class="btn btn-primary" id="refuse" (click)="refuse()" *ngIf="canBeRefused()">
            Refuser la demande
        </button>
        <button class="btn btn-primary" id="cancel" (click)="cancel()" *ngIf="canBeCancelled()">
            Annuler la demander
        </button>
        <button class="btn btn-success" id="accept" (click)="accept()" *ngIf="canBeAccepted()">
            Accepter la demander
        </button>
    </div>

</div>