import {Observable} from "rxjs";
import {StaffMemberSummary} from "../models/staff/StaffMemberSummary.model";
import {StaffMemberDetail} from "../models/staff/StaffMemberDetail";
import {AddressWithHistory} from "../models/address/AddressWithHistory";
import {TimeOutCompRequest} from "../models/staff/activity/TimeOutCompRequest";
import {TimeOffRequest} from "../models/staff/activity/TimeOffRequest";
import {StaffActivityRequest} from "../models/staff/activity/StaffActivityRequest";
import {SickLeaveRequest} from "../models/staff/activity/SickLeaveRequest";

export abstract class StaffGateway {
  abstract retrieveAll(): Observable<StaffMemberSummary[]>;

  abstract retrieve(id: number): Observable<StaffMemberDetail>;

  abstract updateCurrentAddress(staffMemberId: number, address: AddressWithHistory): Observable<StaffMemberDetail>;

  abstract retrieveActiveEducators(): Observable<StaffMemberSummary[]>;

  abstract findActivityRequestsForStaff(staffMemberId: number): Observable<StaffActivityRequest[]>;

  abstract findActivityRequests(year: number): Observable<StaffActivityRequest[]>;

  abstract findActivityRequest(activityId: number): Observable<StaffActivityRequest>;

  abstract createSickLeaveRequest(staffMemberId: number, request: SickLeaveRequest): Observable<number>;

  abstract createTimeOutRequest(staffMemberId: number, request: TimeOutCompRequest): Observable<number>;

  abstract createTimeOffRequest(staffMemberId: number, request: TimeOffRequest): Observable<number>;

  abstract cancelActivityRequest(activityId: number): Observable<void>;

  abstract refuseActivityRequest(activityId: number, refusalNote: string): Observable<void>;

  abstract acceptTimeOffRequest(activityId: number): Observable<void>;

  abstract acceptTimeOutRequest(activityId: number): Observable<void>;
}
