<h1>Congés et absences</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="typeFilter" name="requestType" multiple>
                <mat-option *ngFor="let type of StaffActivityTypeEnum.VALUES" [value]="type">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Statut</mat-label>
            <mat-select [(ngModel)]="statusFilter" name="requestStatus" multiple>
                <mat-option *ngFor="let status of StaffActivityRequestStatusEnum.VALUES" [value]="status">
                    {{ status.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <a (click)="create()" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Créer une demande
            </div>
        </a>
    </div>

    <app-repit-pager [items]="requests()"
                     [itemTemplate]="cardTemplate">
        <ng-template #cardTemplate let-request>
            <app-activity-request-card class="card repit-card"
                                       [request]="request"
                                       [goToUrl]="'/my-work/request/detail'"
            ></app-activity-request-card>
        </ng-template>
    </app-repit-pager>

</div>