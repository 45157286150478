<mat-form-field>
    <mat-label>{{ label() }} :</mat-label>
    <mat-select [compareWith]="compareFunction()" [formControl]="formControl">
        <input type="search" class="select-filter" [(ngModel)]="filter"
               [ngModelOptions]="{standalone: true}"
               placeholder="Rechercher">
        <mat-option *ngFor="let item of filteredItems()" [value]="item">
            {{ optionDisplay()(item) }}
        </mat-option>
    </mat-select>
</mat-form-field>