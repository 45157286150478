<div class="m-3">
    <h1 mat-dialog-title>Créer une absence maladie</h1>
    <mat-dialog-content>
        <div class="vstack gap-2 align-items-center">
            <form [formGroup]="form">
                <app-select-with-filter
                        label="Concerne"
                        [controlName]="'staffMember'"
                        [items]="activeStaffMembers()"
                        [compareFunction]="byId"
                        [sortFunction]="sortByFullName"
                        [optionDisplay]="fullName">
                </app-select-with-filter>
                <mat-form-field>
                    <mat-label>Période</mat-label>
                    <mat-date-range-input [rangePicker]="datePicker">
                        <input matStartDate placeholder="Début" formControlName="startDate">
                        <input matEndDate placeholder="Fin" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #datePicker></mat-date-range-picker>
                </mat-form-field>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="createAndAccept" [disabled]="!isFormValid()"
                (click)="create()">Créer
        </button>
        <button class="btn btn-primary" id="createAndView" [disabled]="!isFormValid()" (click)="createAndClose(true)">
            Créer
            et fermer
        </button>
    </mat-dialog-actions>
</div>