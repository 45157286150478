<div [routerLink]="[goToUrl() + '/' + request().id]">
    <div class="card-header d-flex gap-2">
        <div>
            <div *ngIf="!showStaffName()" class="card-title">{{ request().type.label }}</div>
            <div *ngIf="showStaffName()" class="card-title">{{ request().staffMemberFullName }}
                - {{ request().type.label }}
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="card-text">
            <div>{{ request().status.label }}</div>
            <div *ngIf="!shouldShowTime()">{{ request().startDate | date:"dd/MM/YYYY" }}
                - {{ request().endDate | date:"dd/MM/YYYY" }}
            </div>
            <div *ngIf="shouldShowTime()">{{ request().startDate | date:"dd/MM/YYYY" }} {{ request().startDate | date:"HH:mm" }}
                - {{ request().endDate | date:"HH:mm" }}
            </div>
        </div>
    </div>
</div>