import {Component, computed, inject, Signal, signal} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {
  CreateStaffActivityRequestDialogComponent
} from "../create-staff-activity-request-dialog/create-staff-activity-request-dialog.component";
import {StaffActivityTypeEnum} from "../../../models/staff/activity/StaffActivityType";
import {StaffActivityRequestStatusEnum} from "../../../models/staff/activity/StaffActivityRequestStatus";
import {StaffActivityRequestFilter, StaffState} from "../../../stores/staff/staff.state";
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {Store} from "@ngxs/store";
import {FindMyStaffActivityRequests} from "../../../stores/staff/staff.actions";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";
import {PrestationStatusEnum} from "../../../models/prestation/PrestationStatusEnum";
import {FormsModule} from "@angular/forms";
import {RepitPagerComponent} from "../../components/repit-pager/repit-pager.component";
import {ActivityRequestCardComponent} from "../../activity/activity-request-card/activity-request-card.component";
import StaffActivityType = StaffActivityTypeEnum.StaffActivityType;
import StaffActivityRequestStatus = StaffActivityRequestStatusEnum.StaffActivityRequestStatus;

@Component({
  selector: 'app-list-staff-activity-request',
  standalone: true,
  imports: [
    MatIcon,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    FormsModule,
    RepitPagerComponent,
    ActivityRequestCardComponent
  ],
  templateUrl: './list-my-activity-request.component.html',
  styleUrl: './list-my-activity-request.component.scss'
})
export class ListMyActivityRequestComponent {
  store = inject(Store);

  typeFilter = signal<StaffActivityType[]>([]);
  statusFilter = signal<StaffActivityRequestStatus[]>([]);

  filter = computed(() => ({
    type: this.typeFilter(),
    status: this.statusFilter()
  } as StaffActivityRequestFilter))

  requests: Signal<StaffActivityRequest[]> = toSignal(this.store.select(StaffState.myActivityRequests))

  constructor(public dialog: MatDialog) {
    const filterFromState = this.store.selectSnapshot(StaffState.myActivityRequestFilter);
    this.typeFilter.set(filterFromState.type);
    this.statusFilter.set(filterFromState.status);
    toObservable(this.filter).subscribe(filter => {
      this.store.dispatch(new FindMyStaffActivityRequests(filter));
    })
  }

  create() {
    this.dialog.open(CreateStaffActivityRequestDialogComponent);
  }

  protected readonly PrestationStatusEnum = PrestationStatusEnum;
  protected readonly StaffActivityRequestStatusEnum = StaffActivityRequestStatusEnum;
  protected readonly StaffActivityTypeEnum = StaffActivityTypeEnum;

}
