import {Component, inject, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {toSignal} from "@angular/core/rxjs-interop";
import {StaffSelectors} from "../../../stores/staff/staff.selectors";
import {byId} from "../../../models/common/compareUtils";
import {SelectWithFilterComponent} from "../../components/select-with-filter/select-with-filter.component";
import {fullName, sortByFullName} from "../../../models/person/PersonUtils";
import {ConfirmSickLeaveCreation, RetrieveStaffMembers} from "../../../stores/staff/staff.actions";

@Component({
  selector: 'app-create-sick-leave-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatEndDate,
    MatFormField,
    MatHint,
    MatLabel,
    MatStartDate,
    MatSuffix,
    ReactiveFormsModule,
    SelectWithFilterComponent
  ],
  templateUrl: './create-sick-leave-dialog.component.html',
  styleUrl: './create-sick-leave-dialog.component.scss'
})
export class CreateSickLeaveDialogComponent implements OnInit {

  store = inject(Store);
  form: FormGroup = new FormGroup({
    staffMember: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl(null, [Validators.required])
  });
  activeStaffMembers = toSignal(this.store.select(StaffSelectors.activeStaffMembers()));

  ngOnInit(): void {
    this.store.dispatch(new RetrieveStaffMembers());
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  create() {
    this.createAndClose(false);
  }

  createAndClose(close: boolean = true) {
    if (this.isFormValid()) {
      this.store.dispatch(new ConfirmSickLeaveCreation({
        staffMemberId: this.form.get('staffMember').value.id,
        startDate: this.form.get('startDate').value,
        endDate: this.form.get('endDate').value
      }, close));
      this.resetForm();
    }
  }

  protected readonly byId = byId;
  protected readonly sortByFullName = sortByFullName;
  protected readonly fullName = fullName;

  private resetForm() {
    this.form.reset();
  }
}
